import React, {useEffect, useState} from "react";
import "./Modal.css";

export default function Modal({ active, setActive }) {

    const [toolTip, showTooltip] = useState(false)

    const handler = () => {
        showTooltip(true)
    }

    const changeFocus = () => {
        showTooltip(false)
    }

    useEffect(() => {
        const closeEsq = (e) => {
            if (e.keyCode === 27) {
                setActive(false)
            }
        }
        window.addEventListener('keydown', closeEsq)
        return () => window.removeEventListener('keydown', closeEsq)
    })

    return (
        <div
            className={active ? "modal active" : "modal"}
            onClick={() => setActive(false)}
        >
            <div className="modal__content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <button onClick={() => setActive(false)} className="close">×</button>
                </div>
                <div className="modal-body">
                    <h4>Получите онлайн-курс прямо сейчас!</h4>
                    <input onFocus={changeFocus} name="email" placeholder="Ваш email" type="email" className="modal_mail js-email"/>
                    {toolTip && <p className="text">Неверный e-mail</p>}
                </div>
                <div className="modal-footer">
                    <button onClick={handler} className="bubbly-button pink_but pulse">Отправить</button>
                </div>
            </div>
        </div>
    );
}