import React from 'react';
import Preloader from "./Preloader";
import './Main.css';
import Faq from './Faq';
import volume from '../images/vol.png';
import img1 from '../images/img1.jpeg';
import img2 from '../images/img2.jpeg';
import img3 from '../images/img3.jpeg';
import img4 from '../images/img4.jpeg';
import img5 from '../images/img5.jpeg';
import img6 from '../images/img6.jpeg';
import img7 from '../images/img7.jpeg';
import img8 from '../images/img8.jpeg';
import img9 from '../images/img9.jpeg';
import img10 from '../images/img10.jpeg';
import img11 from '../images/img11.jpeg';
import img12 from '../images/img12.jpeg';
import login from '../images/login.svg';
import Price from "./Price";
import Footer from "./Footer";
import {Link} from "react-router-dom";

const Main = () => {

    return (
        <>
            <div className="divper">
                <div className="content">
                    <div className="bg_bloc_big" id="up_top">
                        <header>
                            <div className="header_top">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="head_menu">
                                                <div className="head_menu_logo">
                                                    <div className="box_h1">
                                                        <a href="#">gymworkoutsclub.com</a>
                                                        <h2>home workout videos</h2>
                                                    </div>
                                                </div>
                                                <div className="head_menu_login">
                                                    <Link to="/login">
                                                        <img src={login} alt="images"/>
                                                        Login
                                                    </Link>
                                                </div>
                                                <div className="btn_content">
                                                    <div className="btn_divper">
                                                        <a className="btn_support" href="mailto:support@gymworkoutsclub.com">
                                                            <img className="btn_support_img" src={volume} width="24" height="23" alt="img"/>
                                                                Support
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <section className="top_banner">
                            <div className="top_ban_titl">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-12 col-lg-7 col-xl-5">
                                            <p className="p_blol_h1">Dreaming of a beautiful body and want to lose weight effectively?</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-lg-7">
                                            <div className="bott_ban_tit">
                                                <p className="p_min">Lose weight and feel like the best version of yourself. <span>Start training now!</span></p>
                                                <p className="p_blol">A program of proper nutrition and recommendations for intermittent fasting as a <span>gift</span></p>
                                            </div>
                                            <div className="text-center">
                                                <a href="#prise" className="bubbly-button pink_but pulse scroll_a">Get it</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <section id="prise" className="prise_block">
                        <div className="container">
                            <div className="row">
                                <Price/>
                                <p className="loz_prise">
                                    Are you ready to Lose Weight Fast &amp; <span>Transform Your Body</span>?
                                </p>
                            </div>
                        </div>
                    </section>
                    <section className="how_lose">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="title">
                                        <h3>How to Lose <span>4-8 Pounds in a Month</span></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-md-3">
                                    <div className="how_lose_one">
                                        <div className="how_lose_one_img">
                                            <img src={img1} alt="images"/>
                                        </div>
                                        <div className="how_lose_one_text">
                                            Adding cardio to your routine is one of the most effective ways to lose weight quickly.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <div className="how_lose_one">
                                        <div className="how_lose_one_img">
                                            <img src={img2} alt="images"/>
                                        </div>
                                        <div className="how_lose_one_text">
                                            Using gym equipment or performing bodyweight exercises at home are two easy and effective ways to start resistance training and enhance weight loss.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <div className="how_lose_one">
                                        <div className="how_lose_one_img">
                                            <img src={img3} alt="images"/>
                                        </div>
                                        <div className="how_lose_one_text">
                                            High-intensity interval training (HIIT) is a type of exercise that alternates short periods of intense activity with aerobic exercises, keeping your heart rate up to boost fat burning and accelerate weight loss.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <div className="how_lose_one">
                                        <div className="how_lose_one_img">
                                            <img src={img4} alt="images"/>
                                        </div>
                                        <div className="how_lose_one_text">
                                            Cutting down on carbs is another simple way to improve the quality of your diet and lose weight.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <div className="how_lose_one">
                                        <div className="how_lose_one_img">
                                            <img src={img5} alt="images"/>
                                        </div>
                                        <div className="how_lose_one_text">
                                            Fiber is a nutrient that moves through your body undigested, helping stabilize blood sugar, slow down stomach emptying and keeps you feeling full longer.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <div className="how_lose_one">
                                        <div className="how_lose_one_img">
                                            <img src={img6} alt="images"/>
                                        </div>
                                        <div className="how_lose_one_text">
                                            Increasing protein intake can help you lose weight by reducing your appetite and decreasing your calorie consumption.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <div className="how_lose_one">
                                        <div className="how_lose_one_img">
                                            <img src={img7} alt="images"/>
                                        </div>
                                        <div className="how_lose_one_text">
                                            Vegetables are incredibly nutrient-dense, and contain a lot of vitamins, minerals, antioxidants and fiber for a low number of calories.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <div className="how_lose_one">
                                        <div className="how_lose_one_img">
                                            <img src={img8} alt="images"/>
                                        </div>
                                        <div className="how_lose_one_text">
                                            Intermittent fasting involves alternating between eating and fasting periods, with fasting periods typically lasting 16 hours.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <p className="loz_how">IT’S ALL INCLUDED IN OUR WEIGHT LOSS WORKOUT & DIET PLAN</p>
                                    <div className="text-center">
                                        <a href="#prise2" className="bubbly-button pink_but scroll_a pulse">I WANT THIS PROGRAM</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="prise2" className="prise_block2">
                        <div className="container">
                            <div className="row">
                                <Price/>
                            </div>
                        </div>
                    </section>
                    <section className="what_you">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="title">
                                        <h3>WHAT <span>YOU GET</span></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="what_you_one">
                                        <div className="what_you_img bef_rig">
                                            <img src={img9} alt="images"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="what_you_text">
                                        <div>
                                            <h3 className="ico_01"><span>The Weight Loss Workout & Diet Program</span></h3>
                                            <p>
                                                This Weight Loss Workout Program & Diet Plan is the way to lose weight as fast as possible. This program is suitable for everyone. For beginners and more advanced men and women.
                                                Bodyweight training has many more benefits than going to the gym. It’s a functional way of training in which you workout multiple muscle groups and therefore burn more fat! Moreover, the exercises are easy for anyone at any level! The program is designed for 24 days. Although you can get a subscription for a month or a year.
                                                The Program consists of two different plans: The Weight Loss Workout Program and the Weight Loss Diet Plan. We are sure we can help you to lose weight fast with the Weight Loss Challenge!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 order-sm-4">
                                    <div className="what_you_one">
                                        <div className="what_you_img bef_lef">
                                            <img src={img10} alt="images"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="what_you_text">
                                        <div>
                                            <h3 className="ico_02"><span>Training format - "Repeat after me"</span></h3>
                                            <p>
                                                Our course consists of 24 video lessons, the duration of each of them is from 30 minutes to 1 hour 17 minutes. The exercises are prepared in a way that will help you achieve maximum results. The set of exercises aims at waist shaping and strengthening of the abdominal muscles, and includes such exercises as crunches, twists, bends. Also, the sessions are devoted to working on the glutes and legs line . These exercises help remove body fat and cellulite from problem areas, tighten the silhouette, and give a beautiful shape to the body. The combination of deep squats, lunges, half-squats and other effective exercises will work through all your muscles: from the inner to the outer ones.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="what_you_one">
                                        <div className="what_you_img bef_rig">
                                            <img src={img11} alt="images"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="what_you_text">
                                        <div>
                                            <h3 className="ico_03"><span>A Customized Meal Plan</span></h3>
                                            <p>
                                                Everyone is different and that’s why we developed a custom meal plan that creates your own Personal Weight Loss Diet Plan with the right number of calories and nutrients
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 order-sm-8">
                                    <div className="what_you_one">
                                        <div className="what_you_img bef_lef">
                                            <img src={img12} alt="images"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="what_you_text">
                                        <div>
                                            <h3 className="ico_04"><span>Program Requirements</span></h3>
                                            <div>
                                                The 24-Day (or longer) Weight Loss Challenge has no requirements.
                                                The Weight Loss Program is accessible and challenging for everyone:
                                                <ul>
                                                    <li>
                                                        man or woman;
                                                    </li>
                                                    <li>
                                                        a complete beginner or more advanced;
                                                    </li>
                                                    <li>
                                                        interested in calisthenics or not;
                                                    </li>
                                                    <li>
                                                        age of 40+.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <p className="loz_how">We created this Weight Loss Program specifically for you <span>to get results!</span></p>
                                <div className="text-center">
                                    <a href="#prise3" className="bubbly-button pink_but scroll_a pulse">ADD TO CART</a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="you_can">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="title">
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="tou_can_text">
                                        <p>To lose weight, you need to make a plan. Once, you've decided to shed the extra pounds, stock up on healthy foods and create a realistic workout routine.</p>
                                    </div>
                                    <div className="tou_can_text">
                                        <p>
                                            You can expect to lose an average of
                                            <span>up to 2-4 pounds</span> a week or 8-16 pounds per month if you keep to a balanced meal and exercise plan. Weight loss can fluctuate and vary depending on the time of day, your metabolism, and the extent of your lifestyle changes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="prise3" className="prise_block3">
                        <div className="container">
                            <div className="row">
                                <Price/>
                            </div>
                        </div>
                    </section>
                    <section className="results">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="title">
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="res_tit">
                                        <p>Accomplish all your <span>get-healthy, be-stronger, love-your-body</span> intentions with our weight loss challenge—and be ready to experience lasting results.
                                        </p>
                                        <p>
                                            By making some small changes to your daily routine, you can safely lose up to 16 pounds in just one month, hitting your weight loss goals quickly and easily.
                                            Do you want to lose weight in 30 days? It’s true, you can lose those extra pounds in just one month!
                                            To guarantee weight loss, you have to be slow and consistent. The best way to lose extra weight in just 30 days is a combination of regular exercise and a healthy diet plan
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="text-center">
                                        <a href="#prise4" className="bubbly-button pink_but scroll_a pulse">ADD TO CART</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="advantages">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="title">
                                        <h3>Advantages <span>of the course</span></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row obvod">
                                <div className="col-sm-6">
                                    <div className="advantages_one">
                                        <ul>
                                            <li>
                                                <svg className="svg-sli">
                                                </svg>
                                                Weight loss of up to 16 pounds
                                            </li>
                                            <li>
                                                <svg className="svg-sli">
                                                </svg>
                                                Feeling and looking fabulous
                                            </li>
                                            <li>
                                                <svg className="svg-sli">
                                                </svg>
                                                Better sleep
                                            </li>
                                            <li>
                                                <svg className="svg-sli">
                                                </svg>
                                                Self-confidence boost
                                            </li>
                                            <li>
                                                <svg className="svg-sli">
                                                </svg>
                                                Extra energy
                                            </li>
                                            <li>
                                                <svg className="svg-sli">
                                                </svg>
                                                Better mood
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="advantages_one">
                                        <ul>
                                            <li>
                                                <svg className="svg-sli">
                                                </svg>
                                                Possibility of habit formation
                                            </li>
                                            <li>
                                                <svg className="svg-sli">
                                                </svg>
                                                This program requires only you, some space and your time
                                            </li>
                                            <li>
                                                <svg className="svg-sli">
                                                </svg>
                                                You don’t need access to a crowded, expensive gym, instead, you can train anywhere, anytime.
                                            </li>
                                            <li>
                                                <svg className="svg-sli">
                                                </svg>
                                                It is easy to follow and understand all the exercises.
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="text-center">
                                        <a href="#prise4" className="bubbly-button pink_but scroll_a pulse">GET STARTED</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="obg_loz">
                        <div className="obg_loz_one">
                            It won't be easy, but it will be worth it.
                        </div>
                        <div className="obg_loz_to">
                            Trust us, your future self will thank you.
                        </div>
                    </section>
                    <Faq/>
                    <section id="prise4" className="prise_block4">
                        <div className="container">
                            <div className="row">
                            </div>
                        </div>
                    </section>
                </div>
                <Footer/>
            </div>
        </>
    );
};

export default Main;