import React from 'react';
import './Policy.css';
import {Link} from "react-router-dom";

const Policy = () => {

    return (
        <div className="terms-wrapper">
            <div className="col-12">
                        <h1>Privacy Policy</h1>
                        <p>Last Update: May 17, 2022</p>
                        <p>This Privacy Policy applies to all user’s personal data that Mystream Limited receives through the website  <Link className="link" to="/">gymworkoutsclub.com</Link>  (“our Product”). This Policy describes what personal data we collect when you use our Product and how and for what it is used.</p>
                        <p>When this Privacy Policy mentions “we”, “us”, or “our” it refers, Mystream Limited Poseidonos,1 LEDRA BUSINESS CENTRE Egkomi, 2406, Nicosia, Cyprus, e–mail – <a className="link" href="mailto:support@gymworkoutsclub.com">support@gymworkoutsclub.com</a>.</p>
                        <p>If you do not agree with this Privacy Policy, PLEASE, do not use our Product.</p>
                        <p>We reserve the right to make changes to this Privacy Policy at any time. We will notify you about any changes by indicating the latest update date each time we make any changes to this Privacy Policy. Any changes shall take effect from the moment they are published in our Product. Your continued use of the Product after the effective date of the updated Privacy Policy will be subject to the new Privacy Policy. If we make any major changes to our Privacy Policy and will need your explicit consent for further processing of your personal data, we will request your consent or your renewed consent (in case it was obtained previously).</p>
                        <h3>1. How we process your personal information.</h3>
                        <p>We process your personal information in the following cases:</p>
                        <p><b>A. DATA THAT YOU PROVIDE TO US</b></p>
                        <p>1.1. When you create an Account through <Link className="link" to="/">gymworkoutsclub.com</Link>, we collect the following data:
                            <br/>•	Your name – we use it to contact you by name if we contact you for technical support and other issues;
                                <br/>•	Your e–mail address – we use it to register you as a user of our Product;
                                    <br/>•	Your mobile phone number – we use it to contact you. Please note that we may call you using the phone number that you provide in our Product to obtain feedback on our Product and to resolve other issues.</p>
                        <p>Relying on the legitimate interest based on the balance of interests we may use the information specified above to make suggestions to you in connection with our Products, including by texting or email.</p>
                        <p>1.2. When you complete your own Account profile, we may collect the following data:
                            <br/>•	Your last name;
                                <br/>•	Your photo.</p>
                        <p>Please note that the above data you can fill in is optional to make your profile completed but it is not required.</p>
                        <p>1.3. When you contact us by email, phone, etc. on any other matter, we will collect and use the data that you provide to us in this case to resolve the matter for which you have contacted us.</p>
                        <p>1.4. When you provide us with your personal information in any other way (e.g., through customer support requests, feedback, etc.), we will collect and use the data you provide in that case to resolve the issue you have raised with us.</p>
                        <p><b>B. DATA THAT IS COLLECTED AUTOMATICALLY</b></p>
                        <p>1.5. Our Product may also automatically by electronic means, including with the help of cookies, collect certain data when you interact with our Product, such as IP address, browser type and language, general location data and usage data, i.e., information about your use of the Product. Learn more about how we use cookies in the clause “Cookies” herein.</p>
                        <p><b>C. PAYMENT DATA</b></p>
                        <p>1.6. When paying within our Product you can provide your payment details. However, we do not process your payment data – all payment data is processed through the third-parties payment service providers.</p>
                        <p><b>D. FEEDBACK INFORMATION</b></p>
                        <p>1.7. If you provide feedback about our Product on any platform on the web, you agree to process your full name (or username), profile photo, and feedback provided, including by posting your feedback in our Product.</p>
                        <p><b>E. INFORMATION FROM YOUR EXTERNAL ACCOUNTS</b></p>
                        <p>1.8. We may enable you to login and authorize through accounts created on external platforms such as Facebook and others (the “External Accounts”). When you access our Product through your External Accounts, we collect from your External Accounts personal information to which you have provided access, such as name, email, personal profile information and preferences. This personal information is initially collected by the External Account operator and made available to us in accordance with its data processing policy. In general, you can control access to your personal information that we obtain from these sources through the privacy settings of your External Account.</p>

                        <h3>2. Disclosure of your personal information.</h3>
                        <p>2.1. We may share your personal information with certain third parties listed below:
                            <br/>•	Third party analysts: Facebook Pixel, Yandex Metrics, Amplitude;
                                <br/>•	Service used for sending messages: sendpulse.com;
                                    <br/>•	E–mail service: business.yandex.ru/mail;
                                        <br/>•	Payment systems available at our Product;
                                            <br/>•	Our hosting provider.</p>
                        <p>You can find out how the above services process your personal information in the documents of the respective service available on the website or on request.</p>
                        <p>2.2. We may also disclose your personal information when we have a good faith belief (1) we are required to do so by law, or (2) it is necessary to detect, prevent and address fraud and other illegal activity.</p>

                        <h3>3. Disclosure of your personal data.</h3>
                        <p>3.1. We may share your personal data with certain third parties listed below:
                            <br/>• Third party analysts: Facebook Pixel, Yandex Metrics, Amplitude;
                                <br/>• Service used for sending messages: sendpulse.com;
                                    <br/>• E–mail service: business.yandex.ru/mail;
                                        <br/>• Payment systems available at our Product;
                                            <br/>• Our hosting provider.
                                                <p/>You can find out how the above services process your personal data in the documents of the respective service available on the website or on request.<p>
                                                    <p/>3.2. We may also disclose your personal data when we have a good faith belief (1) we are required to do so by law, or (2) it is necessary to detect, prevent and address fraud and other illegal activity.<p>

                                                        <h3>4. Cookies.</h3>
                                                        <p/>4.1. Cookies are small text files sent to your computer or mobile device that enable the Product and its features to function properly.<p>
                                                            <p/>4.2. We use session cookies and persistent cookies. Session cookies are temporary cookies that are not stored on your browser or mobile device after a session. They are used for authentication and security purposes and to ensure that the features of our Product that you use are effective and appropriate. We may also use session cookies to remember your preferences when you use our Product. These session cookies will be deleted when you exit our Product. A persistent cookie is a cookie that is placed on your browser or mobile device for a specific period of time after you used our Product.<p>
                                                                <p/>4.3. Some of our cookies are necessary for certain uses of the Product. These cookies allow us to make Product usable by enabling basic functions like page navigation and access to secure areas of the Product. The Product cannot function properly without these cookies.<p>
                                                                    <p/>4.4. We also use third–party cookies for analytical and advertising purposes. Such cookies collect information on how users use the Product and the effectiveness of our third-party advertising. Our Product uses third–party cookies provided by our service providers specified in the clause “Disclosure of your personal data” herein.<p>
                                                                        <p/>4.5. You can disable tracking by using cookies in your browser and mobile device settings or by contacting us at support@ifullyfit.com.<p>

                                                                            <h3>5. Children. </h3>
                                                                            <p>Our Product is not directed to individuals under 18. We do not knowingly collect personal data from children under 18. If we become aware that a child under 18 has provided us with personal data, we will take steps to delete such information. If you become aware that a child has provided us with personal data, please contact our support services at support@ifullyfit.com.

                                                                                <h3>6. Data retention.</h3>
                                                                                <p>We only retain your personal data for as long as is necessary to fulfill the purposes of its processing. Please be advised that we may retain some of your personal data longer if we have lawful basic to do so. For instance, we may retain your personal data as necessary to meet our legal obligations, such as for tax and accounting purposes. If we have no lawful basic for further processing of your personal data, we delete or anonymize your personal data.
                                                                                    <h3>7. No Automated Decision-Making or Automated Profiling</h3>
                                                                                    <p>We do not use automated decision-making or refer to the automated profiling.
                                                                                        <h3>8. Rights that you have and the way you can exercise them</h3>
                                                                                        <p>As GDPR residents our users may exercise certain rights in relation to their personal data as set out in the table below.
                                                                                            <h3>EU Residents’ Rights and Explanation</h3>
                                                                                            <br/>- Right to access personal data
                                                                                                <br/>You may ask what data concerning you is kept by us and receive a copy of it, as well as other supplementary information
                                                                                                    <br/>- Right to rectification
                                                                                                        <br/>At any time you may request the modification of your personal data that you believe is inaccurate or outdated
                                                                                                            <br/>- Right of erasure (“right to be forgotten”)
                                                                                                                <br/>You may request the deletion of data concerning you at any time.
                                                                                                                    <br/>* Please note, that we can retain your personal data if necessary to comply with our legal obligations or resolve disputes. If we have no basis to continue processing your personal data, we will delete or remove it and notify you about such deletion or removal
                                                                                                                        <br/>- Right to restrict/suspend processing of personal data
                                                                                                                            <br/>You may request the limitation of the processing of your personal data when one of the following applies: (a) the accuracy of the personal data is contested by you, (b) the processing is unlawful and you oppose the erasure of the personal data, (c) we no longer need the personal data for the purposes of the processing, but they are required by you for the establishment, exercise or defence of legal claims, and (d) you have objected to processing pending the verification whether the legitimate basis overrides this
                                                                                                                                <br/>- Right to data portability
                                                                                                                                    <br/>You may be entitled to obtain your personal data in a structured, commonly used and machine-readable form in order to transfer it to another data controller. Please note that this right only applies to information that we processed based on your consent or Terms of Use
                                                                                                                                        <br/>- Right to be informed
                                                                                                                                            <br/>You have the right to be provided with clear and easy-to-understand information about how we use your personal data
                                                                                                                                                <br/>- The right to object to the processing
                                                                                                                                                    <br/>You may object to the processing of personal data concerning you, where we are relying on a legitimate interest and there is something about your particular situation that makes you want to object to the processing on this basis. We will no longer process the personal data unless we demonstrate compelling legitimate basis for the processing which overrides your interests, rights and freedoms or for the establishment, exercise or defense of legal claims
                                                                                                                                                        <br/>- Right to withdraw consent
                                                                                                                                                            <br/>You may withdraw your consent at any time where we are relying on the consent to process your personal data. If we have no other basis for processing your personal data, after you send us a request to withdraw consent, we will stop processing your personal data that we were processing under your consent
                                                                                                                                                                <br/>- Right to information about information transfers
                                                                                                                                                                    <br/> You have the right to obtain a copy of documents related to the safeguards under which your personal data is transferred outside the EU
                                                                                                                                                                        <br/>- Right to complain to a supervisory authority
                                                                                                                                                                            <br/><p>You have the right to contact the data protection authority in your country to complain about our data protection and privacy practices</p>

                                                                                                                                                                                <p>We aim to comply without undue delay, and within one month at the latest. To address more complex requests, we might need to extend the answering time by a further 2 months. In this case, we will notify you about the extension within 1 month of receipt of your request and will explain you the reasons for such extension.
                                                                                                                                                                                    <h3>Contact us.</h3>
                                                                                                                                                                                    <p>If you have any requests concerning your personal data or any queries with regard to this Privacy Policy, please feel free to contact us at <a className="link" href="mailto:support@gymworkoutsclub.com">support@gymworkoutsclub.com</a>
                                                                                                                                                                                            <br/>
                                                                                                                                                                                                <br/>
                                                                                                                                                                                                    <br/>
                                                                                                                                                                                    </p>
                                                                                                                                                                                </p>
                                                                                        </p>
                                                                                    </p>
                                                                                </p>
                                                                            </p>
                            </p>
                            </p>
                            </p>
                            </p>
                            </p>
                            </p>
                            </p>
                        </p>

            </div>
        </div>
    )
}

export default Policy;