import React, {useEffect, useState} from 'react';
import './Footer.css';
import cards from '../images/fot_banc.jpeg';
import up from '../images/up.png';
import {Link} from "react-router-dom";


const Footer = () => {

    const [scrollY, setScrollY] = useState(0);
    const watchScroll = () => {
        setScrollY(window.scrollY);
    }
    useEffect(() => {
        window.addEventListener("scroll", watchScroll);

        return () => {
            window.removeEventListener("scroll", watchScroll);
        };
    }, []);

    return (
        <>
            <footer className="footer">
                <div className="footer_bottom">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="foot_info"/>
                                <div>
                                    <b>Mystream Limited</b> <br/>
                                    Registration number HE423185 <br/>
                                    Poseidonos,1<br/>
                                    LEDRA BUSINESS CENTRE<br/>
                                    Egkomi, 2406, Nicosia, Cyprus<br/>
                                </div>
                                <p>
                                    Contact email: <a href="mailto:support@gymworkoutsclub.com">support@gymworkoutsclub.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_link">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <Link to="/policy">Privacy Policy</Link>
                                <Link to="/terms">Terms of Use</Link>
                                <Link to="/support">User Support</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_bank">
                    <img src={cards} alt="cards"/>
                </div>
            </footer>
            {scrollY > 200 &&  <div className="up">
                <a href="#">
                    <img src={up} alt="images"/>
                </a>
            </div>}

        </>
    );
};

export default Footer;