import React, {useState} from 'react';
import './Login.css';
import {Link} from "react-router-dom";

const Login = () => {

    const [toolTip, showTooltip] = useState(false)

    const handler = () => {
        showTooltip(true)
    }

    const changeFocus = () => {
        showTooltip(false)
    }

    return (
        <>
        <div id="content" className="snap-content">
            <div className="custom-top">
                <Link className="custom-top-text" to="/">gymworkoutsclub.com</Link>
            </div>
            <div className="background-white ">
                <div className="container ">
                    <div className="row">
                        <div className="col-12 ">
                            <div className="custom_wrapper">
                                <div className="row">
                                    <div className="col-12 pad_0">
                                        <a href="#" className="log_in active" style={{textAlign: "center", textDecoration: "none"}}>Войти</a>
                                    </div>
                                </div>
                                <div>
                                    <div className="input_grop test-email">
                                        <input onFocus={changeFocus} className="test-input-email" name="EMAIL" type="email" placeholder="E-mail"/>
                                    </div>
                                    <div className="input_grop test-password">
                                        <input onFocus={changeFocus} className="test-input-pass" name="PASSWORD" type="password" placeholder="Пароль"/>
                                        {toolTip && <p className="text">*Неверный e-mail или пароль</p>}
                                    </div>
                                    <div className="text-center butt_foot">
                                        <div className="row">
                                            <div className="col-12">
                                                <button onClick={handler} className="violet_butt test-save">Войти</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Login;