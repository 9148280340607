import Main from "./components/Main";
import Terms from "./components/Terms";
import {Routes, Route} from "react-router-dom";
import Policy from "./components/Policy";
import Support from "./components/Support";
import Login from "./components/Login";

function App() {
  return (
    <>
        <Routes>
            <Route exact path="/" element={<Main/>}/>
            <Route path="/policy" element={<Policy/>}/>
            <Route path="/terms" element={<Terms/>}/>
            <Route path="/support" element={<Support/>}/>
            <Route path="/login" element={<Login/>}/>
        </Routes>
    </>
  );
}

export default App;
