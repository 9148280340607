import React from 'react';
import './Support.css';

const Support = () => {
    return (
        <>
            <div className="text_class">
                Для отмены подписки отправьте письмо на&nbsp;&nbsp;
                <a className="link" href="mailto:support@gymworkoutsclub.com">support@gymworkoutsclub.com</a>
            </div>
        </>
    );
};

export default Support;