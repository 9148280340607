import React, {useState} from 'react';
import './Faq.css';

const Faq = () => {

    const [id, setId] = useState(null);

    const handlerItem = (el) => {
        setId(el.id)
    }

    const items = [
        { id: '1', title: '1. How does it work?', content: 'Enter your email to leave a request. Pay for the selected plan. Login data will be sent to your email. Log in and start training the same day.' },
        { id: '2', title: '2. Where are the video tutorials stored?', content: 'Course videos are uploaded to the online learning platform. After you make the payment you will get access to it' },
        { id: '3', title: '3. When should I train?', content: 'You can train anytime, anywhere. All you need is an internet access and a mat. All sessions are pre-recorded.' },
        { id: '4', title: "4. What's included in the course?", content: 'The course program consists of 24 video workouts, the duration of each session is 30 minutes - 1 hour 17 minutes. The session consists of exercises for warming up and the most effective exercises for losing weight.' },
        { id: '5', title: '5. How long will I have access to the workouts?', content: 'Choose one of three plans and gain access according to your payment plan' },
    ];

    return (
        <>
            <section className="faq">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">
                                <h3>FAQ <span>FREQUENTLY ASKED QUESTIONS</span></h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {items.map(el => (
                                <div key={el.id} onClick={() => handlerItem(el)} className="card">
                                    <div className={el.id === id ? "card-header-active" : "card-header"}>
                                        <h5 className="mb-0">{el.title}</h5>
                                        <div className={el.id === id ? "card-minus": "card-add"}/>
                                    </div>
                                    <div className="card-body">
                                    {el.id === id && <span className="hidden">{el.content}</span>}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Faq;