import React, {useState} from 'react';
import './Price.css';
import Modal from "./Modal";

const Price = () => {

    const [modalActive, setModalActive] = useState(false);

    return (
        <>
            <div className="col-md-4">
                <div className="one"/>
                <div className="prise_one1 platinum">
                    <div className="prise_top"/>
                    <div className="summ">
                        <span className="big_summ1">step 1</span>
                    </div>
                    <div className="prise_body">
                        <ul>
                            <li>
                                Take a test about your habits so
                                we can create a meal plan based
                                on your needs, preferences and
                                goals
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="two"/>
                <div className="prise_one1 gold">
                    <div className="prise_top"/>
                    <div className="summ">
                        <span className="big_summ1">step 2</span>
                    </div>
                    <div className="prise_body">
                        <ul>
                            <li>
                                Get your test results
                                find out how to achieve your
                                desired goal
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="three"/>
                <div className="prise_one1 silver">
                    <div className="prise_top"/>
                    <div className="summ">
                        <span className="big_summ1">step 3 </span>
                    </div>
                    <div className="prise_body">
                        <ul>
                            <li>
                                Follow the recommendations
                                enjoy the changes, feel how
                                comfortable is to lose weight with
                                all the benefits offered by our program
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="prise_foot1" onClick={() => setModalActive(true)}>
                <a className="bubbly-button pink_but js-product-click pulse">
                    Get personalized plan
                </a>
            </div>
            <Modal active={modalActive} setActive={setModalActive}/>
        </>
    );
};

export default Price;