import React from 'react';
import './Terms.css';
import {Link} from "react-router-dom";

const Terms = () => {

    return (
        <div className="terms-wrapper">
            <div className="col-12">
                <h1>Terms of Use</h1>
                <p>Last Update: May 17, 2022</p>
                <p>Mystream Limited, Registration number HE423185 (Mystream Limited, “we”, “us” and “our”) provides
                    you access to cross–platform application in the health and fitness category available on our
                    website <Link className="link" to="/">gymworkoutsclub.com</Link> (“our
                    Product”).
                </p>
                <p>Please read these Terms of Use carefully before you start using our Product. Your use of our
                    Product shall be subject to these Terms of Use, and by using our Product, you agree to be bound
                    by them.
                </p>
                <p>We reserve the right, at our sole discretion, to amend these Terms of Use at any time without
                    prior notice. An up–to–date version of these Terms of Use will be available in our Product, and
                    any amendments will come into effect immediately upon posting. By continuing to use our Product,
                    you agree to the amended Terms of Use.
                </p>
                <h3>1. General disclaimer.</h3>
                <p>1.1. Our Product offers a variety of content (video–, audiovisual, text, images, etc.) that can
                    be used for physical activity and diet adjustments. This content is for information purposes
                    only and is not intended as medical advice and is not intended for therapeutic purposes.</p>
                <p>1.2. By using our Product, you confirm that you are solely responsible for your state of health
                    and Mystream Limited and its licensees, if any, are not responsible for any results achieved (or
                    not achieved) by you.</p>
                <p>1.3. When using our Product, you shall follow the below mentioned rules all the time:</p>
                <p>• You shall consult with your physician provider before engaging in any physical activity or
                    changing your nutrition plan. Do not change your physical activity regimen or nutrition plan if
                    your physician provider does not advise you to do so;
                    <br/>• You agree that the use of our Product is no substitute for a consultation with a
                    specialized doctor;
                    <br/>• You shall stop any physical activity whenever you feel faintness, dizziness, pain,
                    shortness of breath or any other uncomfortable symptoms while exercising;
                    <br/>• You should understand that when participating in any exercise, there is the
                    possibility of physical injury. If you engage in any exercise, you agree that you
                    (1) engage at your own risk, (2) are voluntarily participating in these activities,
                    and (3) assume all risk of injury to yourself.
                </p>
                <p>1.4. We presume that you are in good physical condition that allows you to participate in the
                    exercise. If you (or your family) have a history of high blood pressure or heart disease, or
                    have chest pain when exercising in the past months, we do not recommend you to engage any
                    physical activity. If you smoke, have high cholesterol, are obese, or have a bone or joint
                    problem, a change in physical activity can make you feel worse.</p>
                <p>1.5. You agree that we are not responsible or liable for any injuries or other damages you may
                    sustain that result fr om your use of, or inability to use our Product.</p>
                <h3>2. License and rights on the content.</h3>
                <p>2.1. We grant you revocable, worldwide, non–transferable and non–exclusive license to access and
                    use our Product for personal non–commercial purposes under the relevant payment in accordance
                    with these Terms of Use.</p>
                <p>2.2. The term of the provided license starts from the moment you start use our Product and
                    expires with the termination of the Terms of Use in accordance with the clause “Termination”
                    herein.</p>
                <p>2.3. All contents of our Product are protected by copyright and/or other intellectual property
                    rights. These rights are valid and protected in all forms, on all media and in respect of all
                    technologies, whether existing or developed or created afterwards.</p>
                <p>2.4. As a result of using our Product, no rights to any of content, including video–, audiovisual
                    works, images, trademarks and other intellectual property, shall pass to you.</p>
                <p>2.5. You are not entitled to copy, modify, alter, delete, supplement, publish, transfer, create
                    derivative works from, manufacture or sell products based on our Product and/or it’s
                    content.</p>
                <h3>3. Registration procedure.</h3>
                <p>3.1. To start use our Product you will be required to create an account (the “Account”) and will
                    be asked to provide certain personal information. This personal information will be held and
                    used in accordance with our Privacy Policy.</p>
                <p>3.2. You agree that when creating the Account, you will supply accurate and complete information
                    and that you shall be responsible for keeping it up–to–date.</p>
                <p>3.3. You are responsible for taking all reasonable steps to ensure that no unauthorized person
                    shall have access to your Account (including the information about your login and password
                    combination). We will not be liable in any way for any action taken from your Account if you
                    lose or pass to any third party the information about your login and password combination.</p>
                <p>3.4. You agree that since we are unable to identify the particular person who uses our Product
                    through your Account, we will not be responsible for any losses incurred by you wh ere your
                    Account is used by any third party.</p>
                <p>3.5. To create an Account and access our Product, you must be at least 18 years old and not
                    barred from using our Product under applicable law. If you are aware of anyone under 18 using
                    our Product, please contact us at <a className="link" href="mailto:support@gymworkoutsclub.com">support@gymworkoutsclub.com</a>,
                    and we will take required steps to delete his/her Account and personal information.</p>
                <p>3.6. The Account can be deleted if you send a relevant request to <a className="link" href="mailto:support@gymworkoutsclub.com">support@gymworkoutsclub.com</a>.
                    After your Account deletion, the active subscription will be cancelled, but you have a right to
                    the refund only in the cases specified in the clause “Refund procedure” herein.</p>
                <h3>4. Functionality provided within our Product.</h3>
                <p>4.1. Within our Product you will have access to courses in various areas such as stretching,
                    slimming, twerk and others as well as nutrition plans. Each of the course includes a set of
                    training, arranged in a logical sequence, according to your chosen direction and a specific
                    nutrition plan.</p>
                <p>4.4. All performance indicators of a particular course, including what results can be achieved as
                    you go through the training courses and following the nutrition plan for a certain period of
                    time, are intended for information purposes, and are approximate. Your factual result may differ
                    from the stated due to the specific characteristics of your body, your lifestyle, health and so
                    on.</p>

                <h3>5. Payment procedure. </h3>
                <p>
                    Your payment will be processed by one of the available third-party payment service providers and in
                    accordance with the procedure set forth by such third-party payment service provider. </p>
                <h3>6. Cost for the provision of services and calculation procedure</h3>
                <p>We offer a one-day trial subscription to the service for a small fee in the amount of $ 1 USD. If
                    you do not cancel your subscription before the end of the trial period (twenty-four hours), you
                    will be automatically charged $ 29,00 USD for the a subscription period of one month. Please
                    note that if a trial is offered, this will be explicitly stated on the pricing screen prior to
                    checkout. If this is not the case, you will purchase our subscription without a trial. We may
                    also, from time to time, offer discounts that are extended to the full price without a discount
                    and other offers that may be of interest to you.
                    <p>The subscription automatically renews every month until you cancel it.
                        <p>Please read the current tariff plan of the service for the development of individual
                            information materials.
                            <p>Trial subscription to the service $ 1 USD for 1 day – includes: Access to your
                                Account, meal plan and physical activity plan for the subscription period 1 day
                                (twenty-four hours).
                                <p>Tariff $ 29,00 USD per month – includes: Access to your Account, meal plan and
                                    physical activity plan for every month.

                                    <h3>7. Refund procedure.</h3>
                                    <p>7.1. You are entitled to a refund only in the event that you have not
                                        received access to the Product after proper payment (the “Access
                                        Issues”).</p>
                                    <p>7.2. In case of occurrence of the Access Issues, we ask you to contact us
                                        at <a className="link" href="mailto:support@gymworkoutsclub.com">support@gymworkoutsclub.com</a>.
                                        We will make every effort to resolve the Access Issues within 14 (fourteen)
                                        business days of you notifying us. If we are unable to resolve the Access
                                        Issues within this period, we will make a refund.</p>
                                    <p>7.3. The refund will be made in the same manner in which you made the payment
                                        and according to the procedure established by the third-party payment
                                        service provider through which you made the payment.</p>
                                    <p>7.4. If for any reason beyond our control you have been unable to use our
                                        Product for a certain period of time, have not used any part of the
                                        functionality of our Product that you have paid for, or have not completed
                                        or started any of course, we will not be responsible for that and you will
                                        not be entitled to a refund. By making a payment, you agree that you receive
                                        access to and acquire the right to use the functionality of the Product at
                                        any time and by any means, subject to the limitations set forth in the Terms
                                        of Use. We cannot control and are not responsible for how and at what
                                        frequency you will use the Product.</p>
                                    <p>7.5. If you terminate the Terms of Use, the payments you have made are
                                        non–refundable.</p>
                                    <p>7.6. If we terminate the Terms of Use on our initiative due to your breach of
                                        the Terms of Use, the payments you have made are non–refundable.</p>
                                    <p>7.7. If we terminate the Terms of Use on our own initiative, provided that
                                        you comply with the Terms of Use and you are not at fault for terminating
                                        the Terms of Use, we will refund the amounts paid by you in proportion to
                                        the number of days in which you are unable to access our Product due to the
                                        termination.</p>
                                    <h3>8. Auto-renewal. </h3>
                                    <p>By purchasing the subscription, you agree to the automatic renewal unless you
                                        withdraw your consent to the automatic renewal in your Account.</p>
                                    <h3>9. Technical support.</h3>
                                    <p>In the event of any failures, malfunctions, errors, inaccuracies in the
                                        operation of our Product, you may contact us by sending a corresponding
                                        request to the <a className="link" href="mailto:support@gymworkoutsclub.com">support@gymworkoutsclub.com</a>.
                                    </p>
                                    <h3>10. Restrictions on use of our Product.</h3>
                                    <p>When using our Product, you are prohibited from:
                                        <br/>• Use our Product and the content placed on our Product (video–,
                                        audiovisual, text, images, etc.) for business, profit purposes or use it
                                        in a manner contrary to the Terms of Use or law;
                                        <br/>• Placing any personal information of third parties in our Product,
                                        including home addresses, telephone numbers, passport details,
                                        e–mail addresses, etc.;
                                        <br/>• Place commercial advertisements, commercial offers and
                                        campaign information in our Product;
                                        <br/>• Insult or otherwise violate the rights and freedoms of
                                        other users, third parties or groups;
                                        <br/>• Change the software code of our Product in any way, or
                                        take actions to change the functionality and performance
                                        of our Product;
                                        <br/>• Attempt to gain unauthorized access to any portion
                                        or feature of our Product, or any other systems,
                                        networks, servers, cloud storages connected to
                                        accounts of other users and administrators of our
                                        Product by hacking, organizing DDoS–attacks,
                                        password "mining" or any other illegitimate means.
                                    </p>
                                    <h3>11. License to use comments, feedback and ideas.</h3>
                                    <p>Any comments, feedback, or ideas with respect to our Product are provided by
                                        you on a non–confidential basis and you grant us a perpetual, worldwide
                                        license to use all comments, feedback and ideas you may share with us,
                                        without notice, compensation or acknowledgement to you, for any purposes
                                        whatsoever, including, but not limited to, improving and marketing our
                                        Product.</p>
                                    <h3>12. Third–party websites or platforms.</h3>
                                    <p>Our Product may contain links to third–party websites or platforms that are
                                        not owned or controlled by us. Such links are provided for your reference
                                        only. We do not control such third–party websites platforms and are not
                                        responsible for their content or your use of them, and as a result we do not
                                        accept responsibility for the availability, suitability, reliability, or
                                        content of such third–party websites platforms. Our inclusion of such links
                                        in our Product does not imply any endorsement of the material or the views
                                        expressed within them.</p>
                                    <h3>13. Advertisement.</h3>
                                    <p>We may place advertisements, promotions or sponsorship content in our Product
                                        (the “Ads”). You agree that we cannot always control the content of such
                                        Ads. We are not responsible for any mistakes in the content of such Ads and
                                        will not be liable for any losses incurred as a result of your interaction
                                        with such Ads, including if you have made any decision based on such Ads,
                                        and any goods, products or services offered in such Ads.</p>
                                    <h3>14. Lack of warranties. </h3>
                                    <p>Our Product is provided on an “as is” basis and without any representation or
                                        warranty of any kind, express or implied. In particular, we do not warrant
                                        that our Product will operate fast and uninterruptedly or that any defects
                                        in its performance will be corrected. We do not warrant that our Product
                                        will meet your needs. You use our Product at your own risk.</p>
                                    <h3>15. Indemnification by you. </h3>
                                    <p>You agree to hold harmless and indemnify us and our authorized officers,
                                        directors, employees, agents, partners, licensors, resellers, successors and
                                        assigns from and against any third-party claim arising from or in any way
                                        related to your breach of these Terms of Use or your use of our Product in
                                        violation of applicable laws, rules or regulations, including any liability
                                        or expense arising from all claims, losses, damages (actual and
                                        consequential), suits, judgments, litigation costs and attorney’s fees, of
                                        any kind and nature.</p>
                                    <h3>16. Limitation of liability.</h3>

                                    <p>16.1. You expressly understand and agree that we shall not be liable to you
                                        for any direct, indirect, incidental, special consequential, or exemplary
                                        damages incurred by you, including, but not limited to, any loss of profit
                                        (whether incurred directly or indirectly), any loss of goodwill or business
                                        reputation, any loss of data suffered, cost of procurement of substitute
                                        services, or other loss. The foregoing limitations on our liability shall
                                        apply whether or not we have been advised of or should have been aware of
                                        the possibility of any such losses arising.</p>

                                    <p>16.2. If the limitation of liability provision under applicable law is held
                                        invalid, in any case, our cumulative liability for all claims arising from
                                        or relating to our Product shall be a maximum of 100.00 (one hundred)
                                        USD.</p>

                                    <p>16.3. In no event will we be liable or responsible for any failure or delay
                                        when and to the extent such failure or delay is caused by any circumstances
                                        beyond our reasonable control, including acts of God, flood, fire,
                                        earthquake or explosion, war, terrorism, invasion, riot or other civil
                                        unrest, embargoes or blockades, a national or regional emergency, internet
                                        connection degradation, strikes, labor stoppages or slowdowns or other
                                        industrial disturbances, a passage of a law or any action taken by a
                                        governmental or public authority, including imposing an embargo, export or
                                        import restriction, quota or other restriction or prohibition or any
                                        complete or partial government shutdown, or national or regional shortage of
                                        adequate power or telecommunications (including the deterioration of
                                        internet connection) or transportation.</p>

                                    <h3>17. Termination.</h3>
                                    <p>17.1. You are entitled to terminate these Terms of Use at any time by
                                        unsubscribing or by requesting the deletion of your Account and terminating
                                        the use of our Product.</p>
                                    <p>17.2. We are entitled to terminate these Terms of Use at our initiative
                                        unilaterally without prior notice to you, if you violate any term of these
                                        Terms of Use.</p>
                                    <p>17.3. We are also entitled to terminate these Terms of Use unilaterally by
                                        sending notice to you regardless of the reason of such termination.</p>
                                    <p>17.4. In case of termination of these Terms of Use you shall immediately stop
                                        using our Product.</p>
                                    <p>17.5. The obligations to pay off debts to Mystream Limited, if any, remain
                                        valid until they are fully discharged.</p>
                                    <h3>18. Applicable law.</h3>
                                    <p>18.1. Any issue which is not agreed in the Terms of Use will be governed by
                                        the law applicable to Mystream Limited</p>
                                    <p>18.2. The parties will endeavor to resolve all disputes, controversies and
                                        claims that may arise in connection with the execution, termination or
                                        invalidation of the Terms of Use by negotiations. The party which has any
                                        claims and/or disagreements shall send message to the other party indicating
                                        the claims and/or disagreements that have arisen. The message is sent to the
                                        following e–mail address: to the user – to the e–mail address provided
                                        during registration; to the Mystream Limited - to <a className="link" href="mailto:support@gymworkoutsclub.com">support@gymworkoutsclub.com</a>.
                                    </p>
                                    <p>18.3. If any dispute, disagreement or claim was not be solved by
                                        negotiations, such dispute shall be resolved by the competent state court at
                                        the place of the Mystream Limited location.</p>
                                    <h3>19. Miscellaneous.</h3>
                                    <p>19.1. Severability. Each of the provisions of these Terms of Use is
                                        independent and separable. If any provision is held to be or becomes invalid
                                        or unenforceable in any respect under the applicable law, it will not affect
                                        the legality, validity or enforceability of any other provision of these
                                        Terms of Use. We shall use all reasonable endeavors to replace such
                                        provisions by making amendments to these Terms of Use unilaterally in that
                                        respect with valid provisions and reflect the essence of the replaced
                                        provisions to the maximum extent possible by applicable law.</p>
                                    <p>19.2. Entire agreement. These Terms of Use is the final, complete and
                                        exclusive agreement between you and Mystream Limited with respect to the
                                        subject matters hereof and supersede and merge all prior discussions and
                                        agreements between the parties with respect to such subject matters
                                        (including any prior Terms of Use).</p>
                                    <p>19.3. Titles and interpretation. The clauses titles in these Terms of Use are
                                        for convenience only and have no legal or contractual effect. The word
                                        "including" means "including without limitation".</p>
                                    <p>19.4. Assignment. These Terms of Use and your rights and obligations herein
                                        may not be assigned by you without our prior written consent, and any
                                        attempted assignment in violation of the foregoing will be null and void. We
                                        may assign these Terms of Use without your consent. The terms of these Terms
                                        of Use shall be binding upon assignees.</p>
                                    <p>19.5. Independent contractor. Your relationship to us is that of an
                                        independent contractor, and neither party is an agent or partner of the
                                        other.</p>

                                    <p>19.6. No waiver of rights. Our failure to exercise or enforce any right or
                                        provision of these Terms of Use shall not operate as a waiver of such right
                                        or provision.</p>
                                </p>
                            </p>
                        </p>
                    </p>
                </p>
            </div>
        </div>
    )
}

export default Terms;